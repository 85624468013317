import * as React from 'react';
import axios from 'axios';
import Duty from './Duty';
import Swal from 'sweetalert2';
import { ser } from './gridData';
import { useTheme } from '@emotion/react';
import { CustomizedDataGrid } from '../../components';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog,  AppBar, Toolbar, IconButton, Typography, Slide, Box, Backdrop, CircularProgress, useMediaQuery } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const Services = ({projectId, empId, open, handleClose, goToProjects}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open1, setOpen1] = React.useState(false);

    const close1 = () => setOpen1(false);

    const [loading, setLoading] = React.useState(false);
    const [services, setServices] = React.useState([]);

    const getServices = async(projectId, empId) => {
        setLoading(true);
        await axios.get('/get-supervisor-projects-employees-services', {
            params: {
                projectId: projectId, 
                empId: empId, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => setServices(res.data)).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get tasks. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    React.useEffect(() => {
        getServices(projectId, empId);
    }, [projectId, empId]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress />
        </Backdrop>
    ), []);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}
        PaperProps={{
            sx: {
              height: '650px', 
            }
        }}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Tasks</Typography>
                    <Typography sx={{ ml: 3, cursor: 'pointer' }} variant="subtitle2" component="div" onClick={goToProjects}>GO TO PROJECTS</Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CustomizedDataGrid columns={ser} rows={services} onClick={e => setOpen1(e)}/>
            </Box>
            {loading ? loader : null}
            {open1 ? <Duty projectId={projectId} empId={empId} serviceId={open1} open={Boolean(open1)} handleClose={close1} goToProjects={goToProjects} goToEmp={handleClose}/> : null}
        </Dialog>

    );

};

export default React.memo(Services);