import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Menu, Box, CircularProgress, List, ListItem, Typography, Divider, Button } from '@mui/material';
import { useNotify } from '../Home';

const Notifications = ({anchorEl, close}) => {

    const { setCount } = useNotify();

    const open = Boolean(anchorEl);

    const [data, setData] = React.useState([]);
    const [loading ,setLoading] = React.useState(true);

    const getData = async() => {
        setLoading(true);
        await axios.get('/get-notifications', {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setData(res.data);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get notifications. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const markRead = async(data) => {
        const ids = data.map(obj => obj.id) || [];
        const stringedId = JSON.stringify(ids);
        await axios.post('/mark-read', { ids: stringedId },
            {
                headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
            }
        ).then(() => {
            setCount(0);
            Swal.fire({
                title: 'Done', 
                text: 'Notifications was marked as read!'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to mark notifications as read. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
    };

    const loader = React.useMemo(() => (
        <Box sx={{ width: '100%', height: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress/>
        </Box>
    ), []);

    const empty = React.useMemo(() => (
        <Box sx={{ width: '100%', height: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='subtitle2'>No notifications</Typography>
        </Box>
    ), []);

    React.useEffect(() => {
        getData();
    }, []);

    return (

        <Menu anchorEl={anchorEl} open={open} onClose={close} onClick={close}
        slotProps={{
            paper: {
                elevation: 0,
                sx: {
                    overflow: 'scroll',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    minWidth: { xs: '100%', md: '400px' },
                    minHeight: '600px',
                    maxHeight: '600px', 
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        bgcolor: 'background.paper',
                        zIndex: 0,
                    },
                },
            },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <List>
                {loading ? loader : data.length === 0 ? empty :  data.map((obj, i) => (
                    <React.Fragment key={i}>
                        <ListItem key={i}>
                            {obj.message}
                        </ListItem>
                        <Divider/>
                    </React.Fragment>
                ))}
            </List>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '95%' }}>
                    <Button variant="outlined" fullWidth onClick={() => markRead(data)} sx={{ mb: 1 }}>Mark all as read</Button>
                </Box>
            </Box>
        </Menu>

    );

};

export default React.memo(Notifications);