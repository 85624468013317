import * as React from 'react';
import Swal from 'sweetalert2';
import SideMenu from './components/SideMenu';
import AppNavbar from './components/AppNavbar';
import AppTheme from '../shared-theme/AppTheme';
import { Notifications as NotificationIcon } from '@mui/icons-material';
import { CssBaseline, Box, Stack, styled, CircularProgress, IconButton, Badge } from '@mui/material';

import { chartsCustomizations } from './customization/charts';
import { dataGridCustomizations } from './customization/dataGrid';
import { treeViewCustomizations } from './customization/treeView';

import { Projects, Clients, Employees, DutySheet, ADutySheet, Admin, Reports, Dashboard, Invoice } from '../screens';

import { useUser } from '@clerk/clerk-react';

import axios from 'axios';
import Notifications from './components/Notifications';

const Container = styled(Box)(({ theme }) => ({
    display: 'flex', 
    backgroundImage: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
        backgroundImage: 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
}));

const xThemeComponents = {
    ...chartsCustomizations, 
    ...dataGridCustomizations, 
    ...treeViewCustomizations, 
};

const RouterContext = React.createContext({});
const UserDataContext = React.createContext({});
const NotifyContext = React.createContext({});

export default function Home(props){

    const { user } = useUser();

    const [screen, setScreen] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const [count, setCount] = React.useState(0);

    const onOpen = (e) => setOpen(e.currentTarget);
    const onClose = () => setOpen(null);

    const handleScreen = React.useCallback((i) => {
        setScreen(i);
    }, [setScreen]);

    const getData = React.useCallback(async() => {
        setLoading(true);
        await axios.get('/get-user', {
            params: {
                clerkId: user.id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setUserData(res.data[0]);
            setLoading(false);
        }).catch((e) => {
            Swal.fire({
                title: 'Cannot get user', 
                text: 'Server might be down'
            });
            console.log(`Error: ${e}`);
        });
    }, [setUserData, setLoading, user.id]);

    const getNotify = React.useCallback(async() => {
        await axios.get('/get-notification-count', {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setCount(res.data);
        }).catch((e) => {
            Swal.fire({
                title: 'Cannot get notifications', 
                text: 'Server might be down'
            });
            console.log(`Error: ${e}`);
        });
    }, [setCount]);

    React.useEffect(() => {
        getData();
        getNotify();
    }, [getData, getNotify]);

    const loader = React.useMemo(() => (
        <AppTheme {...props}>
            <Box sx={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress/>
            </Box>
        </AppTheme>
    ), [props]);

    const mainListItems = [
        userData?.role === 'super-admin' &&{ screen: <Dashboard/> },
        { screen: <Clients/> },
        { screen: <Employees/> },
        { screen: <Projects/> },
        { screen: <ADutySheet/> },
        { screen: <Reports/> },
        { screen: <Invoice/> },
        userData?.role === 'super-admin' && { screen: <Admin/> },
    ];

    const mainListItems2 = [
        { screen: <DutySheet/> },
    ];

    const items = userData?.role === 'supervisor' ? mainListItems2 : mainListItems;

    if(loading) return loader;

    return (

        <UserDataContext.Provider value={{ userData }}>
            <RouterContext.Provider value={{ screen, handleScreen }}>
                <NotifyContext.Provider value={{ count, setCount }}>
                    <AppTheme {...props} themeComponents={xThemeComponents}>
                        <CssBaseline enableColorScheme/>
                        <Container>
                            <SideMenu />
                            <AppNavbar />
                            <Box component="main" sx={{  flexGrow: 1, overflow: 'auto' }}>
                                <Stack spacing={2} sx={{ alignItems: 'center', mx: 3, pb: 5, pt: 4, mt: { xs: 8, md: 0 } }}>
                                    {userData?.role === 'supervisor' ? null :
                                    <Box sx={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center', display: { xs: 'none', md: 'flex' }, }}>
                                        <IconButton onClick={onOpen}>
                                            <Badge badgeContent={count} color="primary">
                                                <NotificationIcon />
                                            </Badge>
                                        </IconButton>
                                        {open ? <Notifications anchorEl={open} close={onClose}/> : null}
                                    </Box>}
                                    {items.map((obj, i) => screen === i ? obj.screen : null)}
                                </Stack>
                            </Box>
                        </Container>
                    </AppTheme>
                </NotifyContext.Provider>
            </RouterContext.Provider>
        </UserDataContext.Provider>

    );

};

export const useNotify = () => React.useContext(NotifyContext);
export const useRouter = () => React.useContext(RouterContext);
export const useUserData = () => React.useContext(UserDataContext);