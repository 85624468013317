import * as React from 'react';
import { useRouter, useUserData } from '../Home';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { PeopleRounded as PeopleRoundedIcon, HandymanRounded, EngineeringRounded, NewspaperRounded, AssessmentRounded, AdminPanelSettings, SpaceDashboard, ReceiptLong } from '@mui/icons-material';

export default function MenuContent() {

    const { screen, handleScreen } = useRouter();
    const { userData } = useUserData();

    const mainListItems = [
        userData?.role === 'super-admin' &&{ text: 'Dashboard', icon: <SpaceDashboard /> },
        { text: 'Clients', icon: <PeopleRoundedIcon /> },
        { text: 'Employees', icon: <EngineeringRounded/> },
        { text: 'Projects', icon: <HandymanRounded /> },
        { text: 'Duty Sheet', icon: <NewspaperRounded /> },
        { text: 'Reports', icon: <AssessmentRounded /> },
        { text: 'Invoice', icon: <ReceiptLong /> },
        userData?.role === 'super-admin' && { text: 'Admins', icon: <AdminPanelSettings /> },
    ];

    const mainListItems2 = [
        { text: 'Duty Sheet', icon: <NewspaperRounded /> },
    ];

    const items = userData?.role === 'supervisor' ? mainListItems2 : mainListItems;

    return (

        <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
            <List dense>
                {items?.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton selected={index === screen} onClick={() => handleScreen(index)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>

    );

};