import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { columns } from './gridData';
import ProjectEmployees from './ProjectEmployees';
import { useUserData } from '../../navigation/Home';
import { CustomizedDataGrid } from '../../components';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';
import { Box, Typography, Backdrop, CircularProgress, IconButton } from '@mui/material';

export default function DutySheet() {

    const { userData } = useUserData();

    const [loading, setLoading] = React.useState(true);
    const [open1, setOpen1] = React.useState(false);

    const [data, setData] = React.useState([]);

    const close1 = () => setOpen1(false);

    const getData = async(id) => {
        setLoading(true);
        await axios.get('/get-supervisor-projects', {
            params: {
                empId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => setData(res.data)).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get projects. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    React.useEffect(() => {
        getData(userData?.id);
    }, [userData?.id]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    return (

        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Duty Sheet</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton onClick={() => getData(userData?.id)}>
                        <RefreshIcon/>
                    </IconButton>
                </Box>
            </Box>
            <CustomizedDataGrid columns={columns} rows={data} onClick={e => setOpen1(e)}/>
            {loading ? loader : null}
            {open1 ? <ProjectEmployees open={Boolean(open1)} handleClose={close1} projectId={open1}/> : null}
        </Box>

    );

};