import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { columns } from './gridData';
import { CustomizedDataGrid } from '../../components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Box, Typography, Backdrop, CircularProgress, IconButton, Dialog, Button, DialogTitle, DialogContent, List, ListItem, FormControl, FormLabel, OutlinedInput, Tab, Tabs, Select, MenuItem } from '@mui/material';
import dayjs from 'dayjs';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
    
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>

    );

};

const formatDate = (date) => {
    if(date){
        const day = new Date(date);
        const yyyy = day.getFullYear();
        const mm = String(day.getMonth() + 1).padStart(2, '0');
        const dd = String(day.getDate()).padStart(2, '0');
        return `${mm}/${dd}/${yyyy}`;
    }else{
        const day = new Date();
        const yyyy = day.getFullYear();
        const mm = String(day.getMonth() + 1).padStart(2, '0');
        const dd = String(day.getDate()).padStart(2, '0');
        return `${mm}/${dd}/${yyyy}`;
    }
};

export default function ADutySheet() {

    const date = React.useRef({
        sDate: null, 
        eDate: null, 
    });

    const [index, setIndex] = React.useState(0);

    const handleIndex = (event, newValue) => {
        setIndex(newValue);
    };

    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);

    const [data, setData] = React.useState([]);

    const [Udata, setUData] = React.useState({
        duty: 0, 
        target: '', 
        day: null, 
        status: 'not-approved', 
    });

    const handleUData = React.useCallback((value) => {
        setUData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setUData]);

    const clearUData = () => {
        setUData({
            day: null, 
            duty: 0, 
            status: 'not-approved', 
            target: '', 
        });
    };

    const findAndSet = (list, id) => {
        clearUData();
        if(list){
            const len = list?.length || 0;
            if(len > 0){
                for(let i = 0; i < len; i++){
                    if(list[i].id === id){
                        setUData({
                            duty: list[i].duty, 
                            target: list[i].target, 
                            day: list[i].day, 
                            status: list[i].status || 'not-approved'
                        });
                        break;
                    }
                }
            }
        }
    };

    const close = () => setOpen(false);

    const getData = async(type) => {
        setLoading(true);
        await axios.get(type, {
            params: {
                startDate: date.current.sDate || formatDate(), 
                endDate: date.current.eDate || formatDate(), 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => setData(res.data)).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to fetch dutysheets. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    React.useEffect(() => {
        getData('/get-admin-dutysheet');
    }, []);

    React.useEffect(() => {
        if(index === 0) getData('/get-admin-dutysheet');
        else if(index === 1) getData('/get-admin-dutysheet-not-approved');
        else if(index === 2) getData('/get-admin-dutysheet-approved');
    }, [index]);

    const delDuty = React.useCallback(async(id) => {
        if(window.confirm('Are you sure you want to delete this duty?')){
            setLoading(true);
            await axios.post('/delete-dutysheet', { dutyId: id }, {
                headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
            }).then(() => {
                Swal.fire({
                    title: 'Deleted', 
                    text: 'Duty was deleted'
                });
            }).catch((e) => {
                Swal.fire({
                    title: 'Error', 
                    text: 'Error deleting duty. Check browser logs for more info'
                });
                console.log(`Error: ${e}`);
            });
            setLoading(false);
            close();
        }
    }, []);

    const handleSubmit = React.useCallback(async(e, dutyId) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            duty: data.get('duty'), 
            target: data.get('target'), 
            day: data.get('day'), 
            dutyId: dutyId, 
            status: data.get('status'), 
        };
        setLoading(true);
        await axios.post('/update-dutysheet', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Dutysheet updated'
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to update dutysheet. Check browser logs for more info.'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        close();
    }, []);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    const dialog = React.useMemo(() => (
        <Dialog open={open} onClose={close}>
            <DialogTitle>Edit Duty</DialogTitle>
            <DialogContent sx={{ minWidth: 360 }}>
                <Box component='form' onSubmit={(e) => handleSubmit(e, open)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <List>
                        <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="duty">Duty</FormLabel>
                                <OutlinedInput  required margin="dense" id="duty" name="duty" label="Duty" placeholder="Duty" type="number" fullWidth inputProps={{ maxLength: 5 }} value={Udata.duty} onChange={e => handleUData({ duty: e.target.value })}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="target">Target</FormLabel>
                                <OutlinedInput  required margin="dense" id="target" name="target" label="Target" placeholder="Target" type="text" fullWidth inputProps={{ maxLength: 50 }} value={Udata.target} onChange={e => handleUData({ target: e.target.value })}/>
                            </FormControl>
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="day">Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker required id='day' name='day' slotProps={{ textField: { required: true } }}  value={dayjs(Udata.day)} onChange={e => handleUData({ day: e.target.value })}/>
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="status">Approve</FormLabel>
                                <Select required id='status' name='status' label='Status' sx={{ width: 130 }} value={Udata.status} onChange={e => handleUData({ status: e.target.value })}>
                                    <MenuItem value='approved'>Yes</MenuItem>
                                    <MenuItem value='not-approved'>No</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                    </List>
                    <Box sx={{ flexDirection: 'row', mt: 2 }}>
                        <Button type='submit'>EDIT</Button>
                        <Button onClick={() => delDuty(open)}>DELETE</Button>
                        <Button onClick={close}>CANCEL</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    ), [open, handleSubmit, Udata, handleUData, delDuty]);

    return (

        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <List>
                    <ListItem sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="sdate">Start Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='sdate' name='sdate' disableFuture slotProps={{ textField: { required: true } }} defaultValue={dayjs(new Date())} onChange={e => { date.current.sDate = formatDate(e); }}/>
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{ ml: { xs: 0, md: 2 } }}>
                            <FormLabel htmlFor="edate">End Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='edate' name='edate' disableFuture slotProps={{ textField: { required: true } }} defaultValue={dayjs(new Date())} onChange={e => { date.current.eDate = formatDate(e); }}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                </List>
                <Tabs value={index} onChange={handleIndex} variant="fullWidth">
                    <Tab label="All" />
                    <Tab label="Not Approved"/>
                    <Tab label="Approved"/>
                </Tabs>
            </Box>
            <CustomTabPanel value={index} index={0}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                    <Typography component="h2" variant="h6" sx={{ mb: 2 }}>All Duty Sheets</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconButton onClick={() => getData('/get-admin-dutysheet')}>
                            <RefreshIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <CustomizedDataGrid columns={columns} rows={data} onClick={e => {
                    findAndSet(data, e);
                    setOpen(e);
                }}/>
            </CustomTabPanel>
            <CustomTabPanel value={index} index={1}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                    <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Not Approved Duty Sheets</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconButton onClick={() => getData('/get-admin-dutysheet-not-approved')}>
                            <RefreshIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <CustomizedDataGrid columns={columns} rows={data} onClick={e => {
                    findAndSet(data, e);
                    setOpen(e);
                }}/>
            </CustomTabPanel>
            <CustomTabPanel value={index} index={2}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
                    <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Approved Duty Sheets</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <IconButton onClick={() => getData('/get-admin-dutysheet-approved')}>
                            <RefreshIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <CustomizedDataGrid columns={columns} rows={data} onClick={e => {
                    findAndSet(data, e);
                    setOpen(e);
                }}/>
            </CustomTabPanel>
            {loading ? loader : null}
            {open ? dialog : null}
        </Box>

    );

};