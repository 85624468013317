import * as React from 'react';
import { Chip } from '@mui/material';
import { toDate } from '../../functions';

function renderStatus(status) {

    const colors = {
        active: 'info', 
        completed: 'success', 
        pending: 'warning', 
    };

    return <Chip label={status} color={colors[status]} size="small" />;

};

function renderDate(value){
    const val = toDate(value);
    return <Chip label={val} color='default' size="small" />;
};

export const columns = [
    {
        field: "serial-number",
        headerName: "Hash",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'name', 
        headerName: 'Project Name', 
        flex: 1.5, 
        minWidth: 200 
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => renderStatus(params.value),
    },
    {
        field: 'clientName',
        headerName: 'Client Name',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: 'supervisorName',
        headerName: 'Name',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'startDate',
        headerName: 'Started On',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 150,
        renderCell: (params) => renderDate(params.value),
    },
];

export const mreq = [
    {
        field: "serial-number",
        headerName: "Hash",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 10
    },
    {
        field: 'item', 
        headerName: 'Materials', 
        flex: 1.5, 
        minWidth: 80, 
    }, 
    {
        field: 'quantity',
        headerName: 'Quantity',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 40,
    },
];

export const team = [
    {
        field: "serial-number",
        headerName: "Hash",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    {
        field: 'empName', 
        headerName: 'Employee Name', 
        flex: 1.5, 
        minWidth: 200, 
    }, 
    // {
    //     field: 'time',
    //     headerName: 'Average working hours',
    //     headerAlign: 'left',
    //     align: 'left',
    //     flex: 1,
    //     minWidth: 80,
    // },
];

export const ser = [
    {
        field: "serial-number",
        headerName: "Hash",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    {
        field: 'name', 
        headerName: 'Task Name', 
        flex: 1.5, 
        minWidth: 200, 
    }, 
    {
        field: 'quantity',
        headerName: 'Quantity',
        headerAlign: 'left',
        align: 'left',
        flex: 0,
        minWidth: 40,
    },
    {
        field: 'serviceType',
        headerName: 'Task Type',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'left',
        align: 'left',
        flex: 0,
        minWidth: 40,
        renderCell: (params) => renderStatus(params.value),
    },
];