import * as React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { columns } from './gridData';
import ProjectInfo from './ProjectInfo';
import CreateProject from './CreateProject';
import { CustomizedDataGrid } from '../../components';
import { RefreshRounded as RefreshIcon } from '@mui/icons-material';
import { Box, Typography, Button, IconButton, Backdrop, CircularProgress } from '@mui/material';

export default function Projects() {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const [projectId, setProjectId] = React.useState(null);
    const [projects, setProjects] = React.useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const clearProjectId = () => setProjectId(null);

    const getProjectData = async() => {
        setLoading(true);
        await axios.get('/get-projects', {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => setProjects(res.data)).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: 'Failed to get projects. Check browser logs for more info'
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    };

    const reload = () => {
        getProjectData();
    };

    React.useEffect(() => {
        getProjectData();
    }, []);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    return (

        <Box sx={{ width: '100%', height: '100vh' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2, mt: 2 }}>
                <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Projects</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton onClick={reload}>
                        <RefreshIcon/>
                    </IconButton>
                    <Button variant="outlined" onClick={handleOpen} sx={{ ml: 2 }}>CREATE</Button>
                </Box>
            </Box>
            <CustomizedDataGrid columns={columns} rows={projects} onClick={e => setProjectId(e)}/>
            {open ? <CreateProject open={open} handleClose={handleClose}/> : null}
            {projectId ? <ProjectInfo projectId={projectId} open={Boolean(projectId)} handleClose={clearProjectId}/> : null}
            {loading ? loader : null}
        </Box>

    );

};