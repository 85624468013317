import axios from 'axios';
import Swal from 'sweetalert2';
import { toDate } from '../../functions';
import { Chip, IconButton } from '@mui/material';
import { DownloadRounded } from '@mui/icons-material';

const downloadInvoice = async(id) => {
    await axios.get('/create-invoice-report', {
        params: {
            invoiceId: id, 
        },
        responseType: 'blob', 
        headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Invoice.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }).catch((e) => {
        Swal.fire({
            title: 'Error', 
            text: 'Failed to create invoice. Check browser logs for more info.'
        });
        console.log(`Error: ${e}`);
    });
};

function renderDate(value){
    const val = toDate(value);
    return <Chip label={val} color='default' size="small" />;
};

function renderButton(id){
    return (
        <IconButton onClick={() => downloadInvoice(id)} sx={{ height: 30, width: 30 }}>
            <DownloadRounded/>
        </IconButton>
    );
};

export const columns = [
    {
        field: "serial-number",
        headerName: "Hash",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'desc', 
        headerName: 'Description', 
        flex: 1.5, 
        minWidth: 200 
    },
    {
        field: 'qty',
        headerName: 'Quantity',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'unitprice',
        headerName: 'Unit Price',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
    },
    {
        field: 'amount',
        headerName: 'Amount',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'taxRate',
        headerName: 'Tax Rate',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'taxAmount',
        headerName: 'Tax Amount',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'netAmount',
        headerName: 'Net Amount',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
];

export const columns2 = [
    {
        field: "serial-number",
        headerName: "Hash",
        filterable: false,
        renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1,
        minWidth: 20
    },
    { 
        field: 'name', 
        headerName: 'Name', 
        flex: 1.5, 
        minWidth: 200 
    },
    {
        field: 'phone',
        headerName: 'Phone',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'invoiceNo',
        headerName: 'Invoice Number',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 100,
    },
    {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        minWidth: 80,
        renderCell: (params) => renderDate(params.value),
    },
    {
        field: 'download',
        headerName: ' ',
        headerAlign: 'left',
        align: 'right',
        flex: 1,
        renderCell: (params) => renderButton(params.id),
    },
];