import * as React from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';

const StatCard = ({ title, value, interval, ...rest }) => {

    return (

        <Card variant="outlined" {...rest}>
            <CardContent>
                <Typography component="h2" variant="subtitle2" gutterBottom>{title}</Typography>
                <Stack direction="column" sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}>
                    <Stack sx={{ justifyContent: 'space-between' }}>
                        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h4" component="p">{value}</Typography>
                        </Stack>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>{interval}</Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>

    );

};

export default React.memo(StatCard);