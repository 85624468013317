import React from 'react';
import { Auth } from './screens';
import Home from './navigation/Home';
import AppTheme from './shared-theme/AppTheme';
import { Box, CircularProgress } from '@mui/material';
import { SignedIn, SignedOut, useUser } from "@clerk/clerk-react";

export default function App(props) {

    const { isLoaded } = useUser();

    const loader = React.useMemo(() => (
        <AppTheme {...props}>
            <Box sx={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress/>
            </Box>
        </AppTheme>
    ), [props]);

    const view = React.useMemo(() => (
        <React.Fragment>
            <SignedOut>
                <Auth/>
            </SignedOut>
            <SignedIn>
                <Home/>
            </SignedIn>
        </React.Fragment>
    ), []);

    if(!isLoaded) return loader;

    return view;

};