import * as React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import DutyList from './DutyList';
import { useTheme } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide, Box, Backdrop, CircularProgress, useMediaQuery, List, ListItem, OutlinedInput, FormControl, FormLabel, Button, Tab, Tabs } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
    
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>

    );

};

const Duty = ({projectId, empId, serviceId, open, handleClose, goToEmp, goToProjects}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [index, setIndex] = React.useState(0);

    const handleIndex = (event, newValue) => {
        setIndex(newValue);
    };

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = async(e, projectId, empId, serviceId) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            duty: data.get('duty'), 
            target: data.get('target'), 
            day: data.get('day'), 
            projectId: projectId, 
            empId: empId, 
            serviceId: serviceId, 
        };
        setLoading(true);
        await axios.post('/create-duty', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: 'Added to dutysheet'
            });
        }).catch((e) => {
            if (e.response && e.response.status === 409) {
                Swal.fire({
                    title: 'Error', 
                    text: e.response.data
                });
            }else{
                Swal.fire({
                    title: 'Error', 
                    text: 'Error adding to dutysheet. Check browser logs for more info'
                });
                console.log(`Error: ${e}`);
            }
        });
        setLoading(false);
    };

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress />
        </Backdrop>
    ), []);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}
        PaperProps={{
            sx: {
              height: '650px', 
            }
        }}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Duty</Typography>
                    <Typography sx={{ cursor: 'pointer' }} variant="subtitle2" component="div" onClick={goToEmp}>GO TO EMPLOYEES</Typography>
                    <Typography sx={{ cursor: 'pointer', ml: 3 }} variant="subtitle2" component="div" onClick={goToProjects}>GO TO PROJECTS</Typography>
                </Toolbar>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={index} onChange={handleIndex} variant="fullWidth">
                        <Tab label="Add to Duty" />
                        <Tab label="List Duties"/>
                    </Tabs>
                </Box>
            </AppBar>
            <CustomTabPanel value={index} index={0}>
                <Box component='form' onSubmit={(e) => handleSubmit(e, projectId, empId, serviceId)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <List>
                        <ListItem sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                            <FormControl>
                                <FormLabel htmlFor="duty">Duty</FormLabel>
                                <OutlinedInput  required margin="dense" id="duty" name="duty" label="Duty" placeholder="Duty" type="number" fullWidth inputProps={{ maxLength: 5 }}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="target">Target</FormLabel>
                                <OutlinedInput  required margin="dense" id="target" name="target" label="Target" placeholder="Target" type="text" fullWidth inputProps={{ maxLength: 50 }}/>
                            </FormControl>
                        </ListItem>
                        <ListItem>
                            <FormControl>
                                <FormLabel htmlFor="day">Date</FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker required id='day' name='day' defaultValue={dayjs(new Date())} slotProps={{ textField: { required: true } }}/>
                                </LocalizationProvider>
                            </FormControl>
                        </ListItem>
                    </List>
                    <Button type='submit' color="inherit" variant='outlined' sx={{ m: 2 }}>SUBMIT</Button>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={index} index={1}>
                <DutyList projectId={projectId} empId={empId} serviceId={serviceId}/>
            </CustomTabPanel>
            {loading ? loader : null}
        </Dialog>

    );

};

export default React.memo(Duty);