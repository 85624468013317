import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { toDate } from '../../functions';
import { useTheme } from '@emotion/react';
import { useUserData } from '../../navigation/Home';
import { Close as CloseIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Button, Dialog, List,  AppBar, Toolbar, IconButton, Typography, Slide, OutlinedInput, FormControl, ListItem, FormLabel, Box, Select, MenuItem, Backdrop, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EmployeeInfo = ({admin, empId, open, handleClose}) => {

    const { userData } = useUserData();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [isOpen ,setIsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [empData, setEmpData] = React.useState(null);

    const [role, setRole] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [gender, setGender] = React.useState(false);

    const toggle = () => setIsOpen(state => !state);

    const handleChange = React.useCallback((value) => {
        setEmpData(state => ({
            ...state, 
            ...value, 
        }));
    }, [setEmpData]);

    const getEmpData = React.useCallback(async(id) => {
        setLoading(true);
        await axios.get('/get-employee-info', {
            params: {
                empId: id, 
            },
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((res) => {
            setEmpData(res.data[0]);
            setStatus(res.data[0]?.status);
            setRole(res.data[0]?.role);
            setGender(res.data[0]?.gender);
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: `Failed to get ${admin ? 'admin' : 'employee'} info. Check browser logs for more info`
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
    }, [admin]);

    const handleSubmit = async(e, id) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const params = {
            id: id, 
            name: data.get('empname'), 
            status: data.get('status'), 
            role: data.get('role'), 
            empcode: data.get('empcode'), 
            dob: data.get('dob'), 
            gender: data.get('gender'), 
            phone: data.get('phone'), 
            email: data.get('email'), 
            address: data.get('address'), 
            jobTitle: data.get('jobTitle'), 
            emptype: data.get('emptype'), 
            salary: data.get('salary'), 
            avgHrs: data.get('hrs'), 
            holidaySalary: data.get('hsalary'), 
            overTimeSalary: data.get('osalary'), 
            comments: data.get('comments'), 
        };
        setLoading(true);
        await axios.post('/update-employee', params, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Done', 
                text: `${admin ? 'Admin' : 'Employee'} was updated`
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: `Error updating ${admin ? 'admin' : 'employee'}. Check browser logs for more info`
            });
            console.log(`Error: ${e}`);
        });
        setLoading(false);
        handleClose();
    };

    const delEmp = React.useCallback(async(id) => {
        await axios.post('/delete-employee', { id: id }, {
            headers: { 'x-api-key': process.env.REACT_APP_CLERK_PUBLISHABLE_KEY }
        }).then((e) => {
            Swal.fire({
                title: 'Deleted', 
                text: `${admin ? 'Admin' : 'Employee'} was deleted`
            });
        }).catch((e) => {
            Swal.fire({
                title: 'Error', 
                text: `Error deleting ${admin ? 'admin' : 'employee'}. Check browser logs for more info`
            });
            console.log(`Error: ${e}`);
        });
        handleClose();
    }, [handleClose, admin]);

    React.useEffect(() => {
        getEmpData(empId);
    }, [empId, getEmpData]);

    const loader = React.useMemo(() => (
        <Backdrop open>
            <CircularProgress/>
        </Backdrop>
    ), []);

    const alerty = React.useMemo(() => (
        <Dialog open={isOpen} onClose={toggle}>
            <DialogTitle>Delete this {admin ? 'admin' : 'employee'}</DialogTitle>
            <DialogContent>
                <DialogContentText>Delete this {admin ? 'admin' : 'employee'}? This action is undoable!</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => delEmp(empId)}>DELETE</Button>
                <Button onClick={toggle} >CANCEL</Button>
            </DialogActions>
        </Dialog>
    ), [isOpen, delEmp, empId, admin]);

    return (

        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{admin ? 'Admin' : 'Employee'} Information</Typography>
                    {admin ? userData.role === 'super-admin' ?  <Button color="error" onClick={toggle}>DELETE</Button> : null : <Button color="error" onClick={toggle}>DELETE</Button>}
                </Toolbar>
            </AppBar>
            <Box component="form" onSubmit={(e) => handleSubmit(e, empId)} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <List>
                    <ListItem>
                        <FormControl fullWidth>
                            <FormLabel>Password: {empData?.password}</FormLabel>
                            {Boolean(empData?.projectName) ? <FormLabel>Currently working on project: {empData?.projectName}</FormLabel> : null}
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="empname">{admin ? 'Admin' : 'Employee'} Name</FormLabel>
                            <OutlinedInput  required margin="dense" id="empname" name="empname" label="Name" placeholder="Name" type="text" fullWidth inputProps={{ maxLength: 50 }} value={empData?.name} onChange={e => handleChange({ name: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="empcode">{admin ? 'Admin' : 'Employee'} Code</FormLabel>
                            <OutlinedInput required margin="dense" id="empcode" name="empcode" label="Code" placeholder="Code" type="text" fullWidth inputProps={{ maxLength: 20 }} value={empData?.empCode} onChange={e => handleChange({ empCode: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="status">Status</FormLabel>
                            <Select required id='status' name='status' label='Status' value={status} onChange={e => setStatus(e.target.value)} sx={{ width: 130 }}>
                                <MenuItem value='on-work' selected={status==='on-work'}>On work</MenuItem>
                                <MenuItem value='on-vacation' selected={status==='on-vacation'}>On vacation</MenuItem>
                                <MenuItem value='on-leave' selected={status==='on-leave'}>On leave</MenuItem>
                                <MenuItem value='available' selected={status==='available'}>Available</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="role">Role</FormLabel>
                            <Select required id='role' name='role' label='Role' value={role} onChange={e => setRole(e.target.value)} sx={{ width: 130 }}>
                                {admin ? <MenuItem value='super-admin'>Super Admin</MenuItem> : null}
                                {admin ? <MenuItem value='admin'>Admin</MenuItem> : null}
                                <MenuItem value='supervisor'>Supervisor</MenuItem>
                                <MenuItem value='none'>None</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="gender">Gender</FormLabel>
                            <Select required id='gender' name='gender' label='Gender' value={gender} onChange={e => setGender(e.target.value)} sx={{ width: 50 }}>
                                <MenuItem value='M'>Male</MenuItem>
                                <MenuItem value='F'>Female</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="dob">Date of Birth</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker required id='dob' name='dob' disableFuture slotProps={{ textField: { required: true } }} value={dayjs(empData?.dob)} onChange={e => handleChange({ dob: e })}/>
                            </LocalizationProvider>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="phone">Phone</FormLabel>
                            <OutlinedInput readOnly required margin="dense" id="phone" name="phone" label="Phone" placeholder="Phone with Country Code" type="tel" fullWidth inputProps={{ maxLength: 15 }} value={empData?.phno}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <OutlinedInput margin="dense" id="email" name="email" label="Email" placeholder="Employee@email.com" type="email" fullWidth inputProps={{ maxLength: 50 }} value={empData?.email} onChange={e => handleChange({ email: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <OutlinedInput margin="dense" id="address" name="address" label="Address" placeholder="Address" type="text" fullWidth inputProps={{ maxLength: 200 }} value={empData?.address} onChange={e => handleChange({ address: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="jobTitle">Job Title</FormLabel>
                            <OutlinedInput required margin="dense" id="jobTitle" name="jobTitle" label="Job Title" placeholder="Job Title" type="text" fullWidth inputProps={{ maxLength: 30 }} value={empData?.jobTitle} onChange={e => handleChange({ jobTitle: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="emptype">{admin ? 'Admin' : 'Employee'} Type</FormLabel>
                            <OutlinedInput required margin="dense" id="emptype" name="emptype" label={admin ? 'Admin Type' : 'Employee Type'} placeholder={admin ? 'Admin Type' : 'Employee Type'} type="text" fullWidth inputProps={{ maxLength: 20 }} value={empData?.empType} onChange={e => handleChange({ empType: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="salary">Salary</FormLabel>
                            <OutlinedInput required margin="dense" id="salary" name="salary" label="Salary" placeholder="Salary" type="number" fullWidth inputProps={{ maxLength: 8 }} value={empData?.salary} onChange={e => handleChange({ salary: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="hrs">Average Working Hours</FormLabel>
                            <OutlinedInput margin="dense" id="hrs" name="hrs" label="Average Working Hours" placeholder="Average Working Hours" type="number" fullWidth inputProps={{ maxLength: 10 }} value={empData?.avgHrs} onChange={e => handleChange({ avgHrs: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl>
                            <FormLabel htmlFor="hsalary">Holiday Salary</FormLabel>
                            <OutlinedInput margin="dense" id="hsalary" name="hsalary" label="Holiday Salary" placeholder="Holiday Salary" type="number" fullWidth inputProps={{ maxLength: 8 }} value={empData?.holidaySalary} onChange={e => handleChange({ holidaySalary: e.target.value })}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor="osalary">Ovetime Salary</FormLabel>
                            <OutlinedInput margin="dense" id="osalary" name="osalary" label="Overtime Salary" placeholder="Overtime Salary" type="number" fullWidth inputProps={{ maxLength: 8 }} value={empData?.overTimeSalary} onChange={e => handleChange({ overTimeSalary: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="comments">Comments</FormLabel>
                            <OutlinedInput multiline margin="dense" id="comments" name="comments" label="Comments" placeholder="Comments" type="text" fullWidth inputProps={{ maxLength: 500 }} value={empData?.comments} onChange={e => handleChange({ comments: e.target.value })}/>
                        </FormControl>
                    </ListItem>
                    <ListItem  sx={{ justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
                        <FormControl fullWidth>
                            <FormLabel>Created at: {toDate(empData?.createdAt)}</FormLabel>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel>Last changed: {toDate(empData?.lastChanged)}</FormLabel>
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <Button type='submit' color="inherit" variant='outlined' fullWidth sx={{ ml: 2, mr: 2 }}>UPDATE</Button>
                    </ListItem>
                </List>
            </Box>
            {loading ? loader : null}
            {isOpen ? alerty : null}
        </Dialog>

    );

};

export default React.memo(EmployeeInfo);