import React from "react";
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { styled, Typography, Card, CardContent, Box, Stack, LinearProgress, linearProgressClasses } from '@mui/material';

const colors = [
    'hsl(220, 20%, 85%)',
    'hsl(220, 20%, 75%)',
    'hsl(220, 20%, 65%)',
    'hsl(220, 20%, 55%)',
    'hsl(220, 20%, 45%)',
    'hsl(220, 20%, 35%)',
    'hsl(220, 20%, 25%)',
    'hsl(220, 20%, 15%)',
];

const StyledText = styled('text', { shouldForwardProp: (prop) => prop !== 'variant' })(({ theme }) => ({
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fill: (theme.vars || theme).palette.text.secondary,
    variants: [
        {
            props: {
                variant: 'primary',
            },
            style: {
                fontSize: theme.typography.h5.fontSize,
            },
        },
        {
            props: ({ variant }) => variant !== 'primary',
            style: {
                fontSize: theme.typography.body2.fontSize,
            },
        },
        {
            props: {
                variant: 'primary',
            },
            style: {
                fontWeight: theme.typography.h5.fontWeight,
            },
        },
        {
            props: ({ variant }) => variant !== 'primary',
            style: {
                fontWeight: theme.typography.body2.fontWeight,
            },
        },
    ],
}));

function PieCenterLabel({ primaryText, secondaryText }) {

    const { width, height, left, top } = useDrawingArea();
    const primaryY = top + height / 2 - 10;
    const secondaryY = primaryY + 24;

    return (

        <React.Fragment>
            <StyledText variant="primary" x={left + width / 2} y={primaryY}>
                {primaryText}
            </StyledText>
            <StyledText variant="secondary" x={left + width / 2} y={secondaryY}>
                {secondaryText}
            </StyledText>
        </React.Fragment>

    );

};

function percent(total, value) {
    if (total === 0) return 0;
    return Math.round((value / total) * 100);
};

const DataChart = ({data, title, totalValue, totalLabel}) => {

    return (

        <Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flexGrow: 1 }}>
            <CardContent>
                <Typography component="h2" variant="subtitle2">{title}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PieChart colors={colors} margin={{ left: 80, right: 80, top: 80, bottom: 80 }}
                    series={[
                        {
                            data: data || [],
                            innerRadius: 75,
                            outerRadius: 100,
                            paddingAngle: 0,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                        },
                    ]}
                    height={260}
                    width={260}
                    slotProps={{
                        legend: { hidden: true }
                    }}>
                        {totalValue ? <PieCenterLabel primaryText={totalValue} secondaryText={totalLabel} /> : null}
                    </PieChart>
                </Box>
                {data?.map((obj, index) => (
                    <Stack key={index} direction="row" sx={{ alignItems: 'center', gap: 2, pb: 2 }}>
                        <Stack sx={{ gap: 1, flexGrow: 1 }}>
                            <Stack direction="row" 
                            sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 2,
                            }}>
                                <Typography variant="body2" sx={{ fontWeight: '500' }}>
                                {obj.label}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {obj.value}
                                </Typography>
                            </Stack>
                            <LinearProgress variant="determinate" value={percent(totalValue, obj.value)} sx={{ [`& .${linearProgressClasses.bar}`]: { backgroundColor:  colors[3] } }}/>
                        </Stack>
                    </Stack>
                ))}
            </CardContent>
        </Card>

    );

};

export default React.memo(DataChart);